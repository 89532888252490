import { action, computed, observable, runInAction, makeObservable } from "mobx";

import { Intersection } from "../types";
import { Annotation, AnnotationSequence, AnnotationsResult, ApiClient, Genome } from "../api";

// What is the limit on how many compared strains can we download at once?
const MAX_NUMBER_DOWNLOADS = 4;

const DEFAULT_GENE_NAME = "hypothetical protein";

// This store is used for the comparison page AND for the details page of a specific
// genome since a lot of it is for the genome track
export default class AnnotationStore {
  api: ApiClient;

  // All the annotations for the selected genomes. All filtering is done afterwards
  @observable annotations: AnnotationsResult = { data: [], metadata: {} };

  // The list of annotation set we are loading, more than 1 for comparisons
  @observable setUuids: string[] = [];
  @observable selectedAnnotation: Annotation | null = null;
  // When we click the jump button in a row, we only want that jump to happen once
  @observable jumpedOnTrack = false;
  @observable jumpedInTable = false;
  @observable includeHypotheticalProteins = true;
  @observable term = "";
  @observable intersection: Intersection | null = null;
  @observable isAmr: boolean | null = null;
  @observable loadingAnnotations = false;
  // For the details page only
  @observable genome: null | Genome = null;
  @observable loadingGenomeDetails = false;
  @observable askForLogin = false;
  @observable error = false;

  constructor(api: ApiClient, ssrData?: AnnotationStore) {
    makeObservable(this);

    this.api = api;
    if (ssrData) {
      this.selectedAnnotation = ssrData.selectedAnnotation;
      this.intersection = ssrData.intersection;
      this.isAmr = ssrData.isAmr;
      this.genome = ssrData.genome;
      this.setUuids = ssrData.setUuids;
      this.term = ssrData.term;
      this.annotations = ssrData.annotations;
      this.error = ssrData.error;
    }
  }

  @action
  async fetchGenomeDetails(uuid: string): Promise<void> {
    this.clear();
    this.loadingGenomeDetails = true;

    const result = await this.api.get<Genome>("/genome-portal-api/genomes/{uuid}", { uuid });
    if (result.error === undefined) {
      const genome = result.data;
      runInAction(() => {
        this.error = false;
        this.genome = observable(genome);
        if (genome.primary_assembly && genome.primary_assembly.primary_annotation_set) {
          this.setUuids = [genome.primary_assembly.primary_annotation_set.uuid];
        }
        this.loadingGenomeDetails = false;
        this.askForLogin = false;
      });
    } else {
      runInAction(() => {
        this.error = true;
        this.loadingGenomeDetails = false;
        this.askForLogin = false;
        this.genome = null;
      });
    }
  }

  @action
  async fetchAnnotations() {
    if (!this.setUuids) {
      return;
    }
    this.loadingAnnotations = true;

    const result = await this.api.get<AnnotationsResult>(
      "/genome-portal-api/annotations",
      {},
      { uuids: this.setUuids.slice() }
    );

    runInAction(() => {
      if (result.error === undefined) {
        this.annotations = result.data;
      }
      this.loadingAnnotations = false;
      // TODO: handle errors
    });
  }

  async getNTSequence(annotationUuid: string): Promise<string | null> {
    const res = await this.api.get<AnnotationSequence>(
      "/genome-portal-api/annotation-sequence/{uuid}",
      {
        uuid: annotationUuid,
      }
    );
    if (res.error === undefined) {
      return res.data.nt_seq;
    } else {
      // TODO: handle errors
      return null;
    }
  }

  async getAASequence(annotationUuid: string): Promise<string | null> {
    const res = await this.api.get<AnnotationSequence>(
      "/genome-portal-api/annotation-sequence/{uuid}",
      {
        uuid: annotationUuid,
      }
    );
    if (res.error === undefined) {
      return res.data.aa_seq;
    } else {
      // TODO: handle errors
      return null;
    }
  }

  @action
  setSetUuids(uuids: string[]) {
    this.clear();
    this.setUuids = uuids;
  }

  @action
  clear() {
    this.genome = null;
    this.askForLogin = false;
    this.loadingGenomeDetails = false;
    this.annotations = { data: [], metadata: {} };

    this.setUuids = [];
    this.selectedAnnotation = null;
    this.jumpedInTable = false;
    this.jumpedOnTrack = false;
    this.includeHypotheticalProteins = true;
    this.term = "";
    this.intersection = null;
    this.isAmr = null;
    this.error = false;
  }

  @action
  selectAnnotation(annotation: Annotation) {
    this.selectedAnnotation = annotation;
    this.jumpedOnTrack = false;
  }

  @action
  trackJumpDone() {
    this.jumpedOnTrack = true;
  }

  @action
  tableJumpDone() {
    this.jumpedInTable = true;
  }

  @action
  toggleIncludeHypotheticalProteins() {
    this.includeHypotheticalProteins = !this.includeHypotheticalProteins;
  }

  @action
  toggleIntersection(intersection: Intersection | "") {
    if (intersection === this.intersection || intersection === "") {
      this.intersection = null;
    } else {
      this.intersection = intersection;
    }
  }

  @action
  toggleAmr(amrVal: boolean | null) {
    this.isAmr = amrVal;
  }

  @action
  updateSearchTerm(term: string) {
    this.term = term;
  }

  @computed
  get isSearching(): boolean {
    return this.term.length >= 3;
  }

  @computed
  get isComparison(): boolean {
    return this.setUuids.length > 1;
  }

  @computed
  get canDownload(): boolean {
    return this.setUuids.length <= MAX_NUMBER_DOWNLOADS;
  }

  @computed
  get searchTerm(): string {
    return this.isSearching ? this.term : "";
  }

  @computed
  get selectedUuid(): string | null {
    if (this.selectedAnnotation) {
      return this.selectedAnnotation.uuid;
    }
    return null;
  }

  @computed
  get selectedAnnotationSetUuid(): string | null {
    if (this.selectedAnnotation) {
      return this.selectedAnnotation.annotation_set.uuid;
    }
    return null;
  }

  @computed
  get hasRightAnnotations(): boolean {
    if (this.setUuids.length === 0) {
      return false;
    }

    for (const v of this.setUuids) {
      if (!this.annotations.metadata[v]) {
        return false;
      }
    }

    return true;
  }

  // In use for the track where we can only toggle hypothetical proteins
  @computed
  get withoutHypotheticalProteins(): Annotation[] {
    return this.annotations.data.filter((a) => a.gene?.product === DEFAULT_GENE_NAME);
  }

  // Applies all the filters that we might need: amr, hypothetical proteins, txt search, intersection
  // In use for the table
  @computed
  get filteredAnnotations(): Annotation[] {
    let filtered = this.annotations.data;

    if (this.isAmr) {
      filtered = filtered.filter((a) => a.gene && a.gene.is_amr);
    }

    if (!this.includeHypotheticalProteins) {
      filtered = filtered.filter((a) => {
        return (a.gene?.product || DEFAULT_GENE_NAME) !== DEFAULT_GENE_NAME;
      });
    }

    if (this.searchTerm) {
      const term = this.searchTerm.toLowerCase();

      filtered = filtered.filter((a) => {
        if (!a.gene) {
          return DEFAULT_GENE_NAME.toLowerCase().includes(term);
        }

        const val = `${a.gene.name}${a.gene.product}${a.gene.ec_number}${a.gene.uniprot_id}${a.gene.gene_type}`;
        return val.toLowerCase().includes(term);
      });
    }

    // Finds genes that co-occur across all annotation sets
    if (this.intersection !== null) {
      const genes: { [key: string]: Annotation[] } = {};
      // First we group things using the field selected intersection
      for (const annotation of filtered) {
        if (!annotation.gene) {
          continue;
        }

        let key = "";

        switch (this.intersection) {
          case "ec":
            if (annotation.gene.ec_number) {
              key = annotation.gene.ec_number;
            }
            break;
          case "gene":
            if (annotation.gene.uuid) {
              key = annotation.gene.uuid;
            }
            break;
          case "uniprot_id":
            if (annotation.gene.uniprot_id) {
              key = annotation.gene.uniprot_id;
            }
            break;
        }

        if (key) {
          if (genes[key] === undefined) {
            genes[key] = [];
          }
          genes[key].push(annotation);
        }
      }
      // Then we check if we have those in all annotation sets and filter the results based on it
      const coOccur: string[] = [];
      for (const key in genes) {
        const sets = new Set(genes[key].map((a) => a.annotation_set.uuid));
        if (sets.size === this.setUuids.length) {
          for (const annotation of genes[key]) {
            coOccur.push(annotation.uuid);
          }
        }
      }

      filtered = filtered.filter((a) => coOccur.indexOf(a.uuid) !== -1);
    }

    return filtered;
  }
}
