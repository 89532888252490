import * as React from "react";
import { useContext } from "react";
import getConfig from "next/config";
import { Button, Icon } from "@blueprintjs/core";
import { StoresContext } from "../../pages/_app";
import { observer } from "mobx-react";

const { publicRuntimeConfig } = getConfig();

const banners = [
  {
    name: "supporting-memberships",
    content: (
      <span>
        Starting May 1, 2024, to guarantee the long-term sustainability of the portal and to
        continue to provide you with reference-quality genomes, we have transitioned the ATCC Genome
        Portal over to a supporting membership service. Learn more about ATCC supporting
        memberships&nbsp;
        <a href="https://www.atcc.org/applications/reference-quality-data/discover-the-atcc-genome-portal">
          here
        </a>
        .
      </span>
    ),
  },
];

const enabledBannersInConfig = publicRuntimeConfig.ENABLED_BANNERS.split(",");
const enabledBanners = banners.filter((banner) => enabledBannersInConfig.includes(banner.name));

interface PageBannerProps {
  content: React.ReactNode;
  dismiss: () => void;
}

const PageBanner = (props: PageBannerProps) => {
  return (
    <div className="page-banners__banner">
      {props.content}
      <Button
        icon={<Icon icon="cross" size={12} />}
        small={true}
        minimal={true}
        onClick={props.dismiss}
      />
    </div>
  );
};

const PageBanners = () => {
  const rootStore = useContext(StoresContext);
  const dismissedBanners = rootStore.userStore.dismissedBanners;

  const displayedBanners = enabledBanners.filter(
    (banner) => !dismissedBanners.includes(banner.name)
  );
  if (displayedBanners.length === 0) {
    return null;
  }

  return (
    <div className="page-banners">
      {displayedBanners.map((banner) => (
        <PageBanner
          key={banner.name}
          content={banner.content}
          dismiss={() => rootStore.userStore.dismissBanner(banner.name)}
        />
      ))}
    </div>
  );
};

export default observer(PageBanners);
